<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        <b>
          {{
            inputs.language === 'en'
              ? 'Select all that apply.'
              : 'Sélectionnez toutes les bonnes réponses possibles.'
          }}
        </b>
        {{
          inputs.language === 'en'
            ? 'For recrystallization, what are the features of an ideal solvent?'
            : "Pour une recristallisation, quelles sont les caractéristiques d'un solvant idéal? Sélectionnez toutes les bonnes réponses possible?"
        }}
      </p>
      <!--  éè-->

      <p
        v-for="option in inputs.language === 'en' ? optionsShuffled : optionsShuffledFr"
        :key="'pt-1-' + option.value"
        class="mb-0 mt-0 pl-8"
      >
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mt-5 mb-0">
        <v-btn-toggle
          v-model="inputs.language"
          class="ml-2"
          size="small"
          dense="dense"
          rounded="rounded"
          mandatory
        >
          <v-btn :value="'en'">English</v-btn>
          <v-btn :value="'fr'">Français</v-btn>
        </v-btn-toggle>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'uOttawa1321Prelab4Q7',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        studentAnswers: [],
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      options: [
        {
          text: 'The solvent dissolves the compound to be purified at low temperatures',
          value: 'highSolubilityLowTemp',
        },
        {
          text: 'The impurities are highly soluble in the solvent',
          value: 'impuritiesHighlySoluble',
        },
        {
          text: 'The solvent offers a large range of solubility for the target product as a function of temperature',
          value: 'largeSolubilityRangeTarget',
        },
        {
          text: 'The impurities are not soluble in the solvent',
          value: 'impuritiesInsoluble',
        },
        {
          text: 'The product must not react with the solvent',
          value: 'mustNotReact',
        },
        {
          text: 'The solvent offers a large range of solubility for the crude product as a function of temperature',
          value: 'largeSolubilityRangeCrude',
        },
      ],
      // é è
      optionsFr: [
        {
          text: 'Le solvant dissout le composé à être purifié à des temperatures inférieures',
          value: 'highSolubilityLowTemp',
        },
        {
          text: 'Les impuretés sont grandement solubles dans le solvant',
          value: 'impuritiesHighlySoluble',
        },
        {
          text: 'La solubilité du produit cible diffère beaucoup en fonction de la température du solvant',
          value: 'largeSolubilityRangeTarget',
        },
        {
          text: 'Les impuretés ne sont pas solubles dans le solvant',
          value: 'impuritiesInsoluble',
        },
        {
          text: 'Le produit ne doit pas réagir avec le solvant',
          value: 'mustNotReact',
        },
        {
          text: 'La solubilité du produit brute diffère beaucoup en fonction de la température du solvant',
          value: 'largeSolubilityRangeCrude',
        },
      ],
    };
  },
  computed: {
    image() {
      return '/img/assignments/organicVsWaterLayer2.png';
    },
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
    optionsShuffledFr() {
      return seededShuffle(this.optionsFr, this.seed);
    },
  },
};
</script>
